<template>
  <div>
  <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'" id="fqcprintTest" style="font-size: 14px;">
    <div style="text-align: right; z-index: 1000;">
      <el-button size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" style="text-align: right;"  type="primary" v-print="'#fqcprintTest'">{{$t('All.打印')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 0 && $store.state.Login.Jurisdiction < 6) || $store.state.Login.Jurisdiction == 0" type="warning" @click="qianhe = true">{{this.$t('All.签核')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 6 && $store.state.Login.Username == form.Jyy) || $store.state.Login.Jurisdiction == 0" type="success" @click="dialogVisibleprin = true">{{this.$t('All.打印贴纸')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 1 || form.State == 3) && $store.state.Login.Username == form.Jyy" type="primary" @click="Newfqcuserzancun(form,0)">{{this.$t('system.songceng')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 1 || form.State == 3) && $store.state.Login.Username == form.Jyy" type="primary" @click="Newfqcuserzancun(form,3)">{{this.$t('system.zancun')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Jyy && (form.State == 1 || form.State == 3) ||  $store.state.Login.Jurisdiction == 0" type="danger" @click="NewfqcdataDel(form)">{{this.$t('system.zuofei')}}</el-button>
      <el-button size="small" type="primary" v-if="!isdisabled"   @click="dialogVisible = true">{{this.$t('system.fjian')}}</el-button>
      <el-button size="small" type="primary" v-if="isdisabled"   @click="dialogVisible = true">{{this.$t('system.chakanfj')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Jyy && (form.State == 1 || form.State == 3) && isdisabled" type="success" @click="isdisabled = false">{{this.$t('system.unlock')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Jyy && (form.State == 1 || form.State == 3) && !isdisabled" type="primary" @click="isdisabled = true" >{{this.$t('system.lock')}}</el-button>
    </div>
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{this.$t('system.成品检验记录')}}</h3>
    <h4 style="text-align: center;">{{this.$t('system.systemid')}}：{{form.Numbers}}</h4>
    <div style="text-align: center;">
      <span v-if="form.State == 6 || form.State == 2" style="text-align: center;font-size: 28px;color: red;">{{$PublicJs.Result(form.Result)}}</span><br>
      <span style="text-align: center;font-size: 28px;color: red;">{{$PublicJs.State(form.State)}}</span>
    </div>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;" >
      <table border="1"  align="center" cellpadding="10" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width: 100%;">
         <tr>
            <td>{{this.$t('FQC.制造班别')}}</td>
            <td >{{this.$t('FQC.工单号')}}</td>
            <td >{{this.$t('FQC.客户')}}</td>
            <td colspan="3">{{this.$t('FQC.品名')}}</td>
            <td colspan="2">{{this.$t('IQC.料号')}}</td>
            <td colspan="2">{{this.$t('system.InspectionDate') }}</td>
          </tr>
          <tr>
            <td class="tdd" v-show="isdisabled">{{ form.Banbi }}</td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Banbi" allow-create filterable :placeholder="this.$t('FQC.请选择')">
                  <el-option
                    v-for="i in $PublicJs.Banbi"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value">
                  </el-option>
                </el-select>
              </td>
              <td class="tdd">{{ form.Workorder }}</td>
              <td class="tdd" v-show="isdisabled">{{ form.Customer }}</td>
              <td class="tdd" v-show="!isdisabled" ><el-input v-model="form.Customer"></el-input></td>
              <td colspan="3" class="tdd">{{ form.Product_name }}</td>
              <td class="tdd" colspan="2">{{ form.Item_no }}</td>
              <td colspan="2" class="tdd">{{ form.Timsdate }}</td>
          </tr>
          <tr>
            <td class="tdd">{{this.$t('All.单位')}}</td>
            <td class="tdd">{{this.$t('FQC.尺寸')}}</td>
            <td class="tdd">{{this.$t('FQC.包装')}}</td>
            <td class="tdd">{{this.$t('FQC.适配')}}</td>
            <td colspan="1" class="tdd">{{this.$t('FQC.颜色')}}</td>
            <td class="tdd">{{this.$t('FQC.插头')}}</td>
            <td colspan="2" class="tdd">CABLE/MOLD</td>
            <td colspan="2">LOT NO.</td>
          </tr>
          <tr>
            <td class="tdd" v-show="isdisabled">{{ form.Company }}</td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Company" filterable allow-create :placeholder="$t('All.单位')" @change="chuangjcompany" :style="{width: '100%'}">
                <el-option label="G" value="G"></el-option>
                <el-option label="KG" value="KG"></el-option>
                <el-option label="PC" value="PC"></el-option>
                <el-option label="M" value="M"></el-option>
                <el-option label="KM" value="KM"></el-option>
              </el-select>
              </td>
              <td class="tdd" v-show="isdisabled">{{ form.Size }}</td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Size" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-show="isdisabled">{{ form.Packing }}</td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Packing" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-show="isdisabled">{{ form.Other }}</td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Other" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-show="isdisabled">{{ form.Colour }}</td>
              <td class="tdd" v-show="!isdisabled"><el-input v-model="form.Colour"></el-input></td>
              <td class="tdd" v-show="isdisabled">{{ form.Plug }}</td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Plug" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd" colspan="2" v-show="isdisabled">{{ form.Cable_MOLD }}</td>
              <td colspan="2" class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Cable_MOLD" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd" colspan="2" v-show="isdisabled">{{ form.Lot_NO }}</td>
              <td colspan="2" class="tdd" v-show="!isdisabled"><el-input v-model="form.Lot_NO"></el-input></td>
          </tr>
          <tr>
            <td class="tdd">{{this.$t('FQC.抽样数')}}</td>
            <td class="tdd">{{this.$t('FQC.交货数')}}</td>
            <td class="tdd">{{this.$t('FQC.入库数')}}</td>
            <td class="tdd">{{this.$t('FQC.箱数')}}</td>
            <td class="tdd" colspan="2">{{this.$t('FQC.参考文件')}}</td>
            <td colspan="2" class="tdd">{{this.$t('IQC.检验基准表')}}</td>
            <td colspan="2" class="tdd">{{this.$t('FQC.图面编号')}}</td>
          </tr>
          <tr>
              <td class="tdd" v-show="isdisabled">{{ form.Sampling }}</td>
              <td class="tdd" v-show="!isdisabled"><el-input v-model="form.Sampling"></el-input></td>
              <td class="tdd" v-show="isdisabled">{{ form.Delivery }}</td>
              <td class="tdd" v-show="!isdisabled"><el-input v-model="form.Delivery"></el-input></td>
              <td class="tdd" v-show="isdisabled">{{ form.Warehousing }}</td>
              <td class="tdd" v-show="!isdisabled"><el-input v-model="form.Warehousing"></el-input></td>
              <td class="tdd" v-show="isdisabled">{{ form.Case_number }}</td>
              <td class="tdd" v-show="!isdisabled"><el-input v-model="form.Case_number"></el-input></td>
              <td class="tdd" v-show="isdisabled" colspan="2">{{ form.Standard2 }}</td>
              <td class="tdd" colspan="2" v-show="!isdisabled"><el-input v-model="form.Standard2"></el-input></td>
              <td class="tdd" colspan="2">
                <el-tooltip effect="dark" :content="form.Inspection_benchmark" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Inspection_benchmarkurl)">{{ form.Inspection_benchmark }}</span>
                  </div>
                </el-tooltip>
              </td>
              <td class="tdd" colspan="2">
                <el-tooltip effect="dark" :content="form.Drawing_number" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Drawing_numberurl)">{{ form.Drawing_number }}</span>
                  </div>
                </el-tooltip>
              </td>
          </tr>
          <tr>
            <td>{{this.$t('FQC.AQL标准')}}</td>
            <td colspan="9">{{this.$t('FQC.抽样标准')}}</td>
          </tr>
          <tr>
             <td >{{this.$t('IQC.检验项目')}}</td>
             <td colspan="2">{{this.$t('IQC.检验标准')}}</td>
             <td colspan="1">{{this.$t('All.检验工具') }}</td>
             <td colspan="2">{{this.$t('All.判定基准') }}</td>
             <td colspan="1">{{this.$t('All.实验频率') }}</td>
             <td colspan="1">{{this.$t('IQC.判定')}}</td>
             <td colspan="2">{{this.$t('IQC.备注')}}</td>
          </tr>
          <tr v-for="(item, index) in form.Fqctestlist" :key="'Fqctestlist' + index">
             <td colspan="1" class="tdd">{{ item.Title }}</td> <!--:rowspan="item.Index" v-if="(item.Index !== 0)"-->
             <td colspan="2" class="tdd">{{ item.Standard }}</td>
             <td colspan="1" class="tdd">{{ item.Tool }}</td>
             <td colspan="2" class="tdd" style="text-align: left;max-width: 200px;"><span>{{ item.Benchmark }}</span></td>
             <td colspan="1">{{$PublicJs.Frequencyfun(item.Frequency) }}</td>
             <td class="tdd" v-if="isdisabled">
                  <span :style="item.Determine == 'Fail'?'color: red;':''">{{ item.Determine }}</span>
              </td>
             <td v-if="!isdisabled">
                <el-select :disabled="((item.Determinetype == 1))" v-model="item.Determine" :placeholder="item.Determinetype == 1?$t('All.实验室判定'):$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
             </td>
             <td colspan="2">
              <el-input type="textarea" v-if="(item.Determinetype == 0) && !isdisabled" :rows="3" v-model="item.Remarks"></el-input>
              <span v-if="(item.Determinetype == 0) && isdisabled">{{ item.Remarks }}</span>
              <el-link @click="lookshiyan(item,index)" type="primary" v-if="item.Determinetype == 1 && item.Remarks.length > 0 && item.Remarks != ''" >{{item.Remarks}}</el-link>
              <el-button size="mini" v-if="item.Determinetype == 1 && !isdisabled && !item.Remarks" type="primary"  @click="Testbox(item, index)" >{{ $t('All.送测单') }}</el-button>
              <el-button style="margin-left: 0 !important;margin-top: 5px;" size="mini" v-if="!isdisabled && item.Determinetype == 1 && (item.Remarks.length === 0 || item.Remarks == '')" type="warning"  @click="mzTestbox(item, index)" >{{ $t('All.免做单') }}</el-button>
            </td>
          </tr>
          <tr>
              <td class="tdd" rowspan="2">{{this.$t('FQC.规格要求')}}</td>
              <td class="tdd" colspan="2">{{this.$t('FQC.量测仪器编号')}}</td>
              <td colspan="7" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Yqish1"></el-input></td>
          </tr>
          <tr>
            <td colspan="1" class="tdd" >{{$t('All.单位') + '/' + $t('All.标注')}}</td>
              <td colspan="5" class="tdd" >{{this.$t('FQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.判定')}}</td>
          </tr>
          <tr v-for="(text, index) in form.Parcss" :key="index">
              <td class="tdd">{{ text.Text }}</td>
              <td class="tdd">{{ text.Text1 }}</td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="text.Text2" v-enter-next-input></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="text.Text3" v-enter-next-input></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="text.Text4" v-enter-next-input></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="text.Text5" v-enter-next-input></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="text.Text6" v-enter-next-input name="Text5" @change="Keyenter(text,index)"></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="text.Text7"></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="text.Text8"></el-input></td>
              <td class="tdd" v-if="!isdisabled">
                <el-select v-model="text.Text9" :disabled = 'isdisabled' :placeholder="$t('All.请选择')" @change="addlist(text,index,$event)">
                    <el-option label="Pass" value="Pass"></el-option>
                    <el-option label="Fail" value="Fail"></el-option>
                    <el-option :label="$t('All.判定')" :value="$t('All.判定')"></el-option>
                    <el-option :label="$t('All.增加')" :value="$t('All.增加')"></el-option>
                    <el-option :disabled="form.Parcss.length == 1" :label="$t('All.删除')" :value="$t('All.删除')"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-if="isdisabled">
                  <span :style="text.Text9 == 'Fail'?'color: red;':''">{{ text.Text9 }}</span>
              </td>
          </tr>
          <tr>
            <td class="tdd"  colspan="5"><el-input :disabled = 'isdisabled' :placeholder="$t('FQC.请输入异常描述')" v-model="form.Ycms" type="textarea" :rows="5"></el-input></td>
            <td class="tdd" style="text-align: left;line-height: 2vw;"  colspan="5">{{form.Beizhu}}</td>
        </tr>
      </table>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('FQC.核准')}}: {{form.Hz}}</span></el-col>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('FQC.检验员')}}: {{form.Jyy}}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
        <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYP1001-02-J</span></el-col>
      </el-row>
    </div>
    <el-form style="max-width: 800px;margin: auto;" size="medium" label-width="90px"
      label-position="top">
    <el-form-item :label="$t('All.签核记录')">
        <el-radio-group  style="padding-bottom: 20px;padding-left: 5%;" v-model="reverse">
          <el-radio :label="true">{{ $t('All.倒序') }}</el-radio>
          <el-radio :label="false">{{ $t('All.正序') }}</el-radio>
        </el-radio-group>
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(activity, index) in Signlog"
            :key="index"
            :timestamp="activity.Date">
            {{ $PublicJs.State(activity.State)}}
            <p>{{activity.Username}}</p>
            <p>{{activity.Notes}}</p>
          </el-timeline-item>
        </el-timeline>
      </el-form-item>
    </el-form>
    <el-dialog
    :title="this.$t('system.fjian')"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose" append-to-body>
      <fqcUpload :isdata = "isdisabled" :fqcadmin = "form"></fqcUpload>
    </el-dialog>
    <el-dialog
    :title="$t('All.打印选择')"
    :visible.sync="dialogVisibleprin"
    width="600px"
    :before-close="handleClose" append-to-body>
    <el-form ref="formrules" status-icon :model="prinform" label-position="right" label-width="95px">
      <!-------------------------------------->
      <!-------------------------------------->
      <el-form-item
        v-for="(domain, index) in prinform.Boxlist"
        :label="$t('All.打印箱')"
        :key="index"
      >
        <el-input v-model="domain.Box" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = null; }" style="width:100px;" autocomplete="off"></el-input>
        <span style="padding: 10px;">{{$t('All.数量')}}:</span><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="domain.Boxquantity" style="width:100px;" autocomplete="off"></el-input><el-button v-show="index + 1 === prinform.Boxlist.length" style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"  @click="addDomainbut()"></el-button><el-button v-show="prinform.Boxlist.length > 1" size="mini" type="danger" icon="el-icon-delete"  @click="removeDomainbut(domain)"></el-button>
      </el-form-item>
      <!-------------------------------------->
      <!-------------------------------------->
      <el-form-item :label="$t('All.检验日期')">
        <el-date-picker
          v-model="prinform.Date"
          type="date"
          disabled
          :placeholder="$t('All.检验日期')" value-format="yyyy/MM/dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('All.打印机')">
        <el-select v-model="prinform.Printer" value-key="Id"  :placeholder="$t('All.请选择打印机')">
          <el-option
            v-for="item in this.$store.state.PrinterData"
            :key="item.Name"
            :label="item.Wz"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="warning" @click="Fqcprint(1)">{{ $t('All.完结不打印贴纸') }}</el-button>
      <el-button type="primary" @click="Fqcprint(0)">{{ $t('All.打印贴纸') }}</el-button>
      <el-button type="primary" v-if="form.State == 2" @click="Fqcprint(2)">{{ $t('All.重新打印贴纸') }}</el-button>
      <el-button @click="dialogVisibleprin = false">{{$t('All.取消')}}</el-button>
    </div>
  </el-dialog>
  <el-dialog
  :title="$t('All.请输入已做实验单号')"
  :visible.sync="yzdialogVisible"
  width="500px"
  :before-close="handleClose" append-to-body>
  {{$t('All.实验单号')}}：<el-input style="width: 300px;border: 1px solid #DCDFE6 !important;border-radius: 5px;" v-model="Mz_Numbers"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="yzdialogVisible = false">{{$t('All.取消')}}</el-button>
    <el-button type="primary" @click="Gettestbaogao()">{{ $t('All.确认') }}</el-button>
  </span>
</el-dialog>
  </div>
  <NewfqcTestsheet v-if="Texttable" :formxin="form" :Test_Numbers="Test_Numbers" :Texttablebool="Texttablebool" :Test_Item="Test_Item" :Test_Index="Test_Index" :Test_Supplier="Test_Supplier" :Test_Product_name="Test_Product_name" :Texttablefunadd="Texttablefunadd"></NewfqcTestsheet>
  <NewFQCqianhe v-if="qianhe" :Qianhecolse = "Qianhecolse" :NewfqcJiean="NewfqcJiean"/>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import fqcUpload from './Upload/NewFQCUpload'
import NewfqcTestsheet from './Upload/NewfqcTestsheet'
import NewFQCqianhe from './Upload/NewFQCqianhe'
import Vue from 'vue'
// 自定义指令，实现按下enter后，光标自动跳转下一个输入框
Vue.directive('enterNextInput', {
  inserted: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      if (charcode === 13) {
        try {
          var dom = document.getElementsByTagName('input')
          for (var i = 0; i < dom.length; i++) {
            if (dom[i] === document.activeElement) {
              if (dom[i].name === 'Text5') {
                dom[i + 6].focus()
              } else {
                dom[i + 1].focus()
              }
              return
            }
          }
        } catch (error) {

        }
      }
    })
  }
})
export default {
  data () {
    return {
      form: {
        Id: 0,
        Timsdate: '', // 检验员填写日期
        Date: new Date(), // 写入时间
        Banbi: '', // 班别
        Workorder: '', // 工单号
        Customer: '', // 客户
        Product_name: '', // 品名规格
        Drawing_number: '', // 图面编号
        Edition: '', // 版本
        LOT_NO: '', // LOT_NO
        Delivery: '', // 交货数
        Warehousing: '', // 入库数
        Case_number: '', // 箱号
        Standard1: '', // AQL标准1
        Standard2: '', //  AQL标准2
        Sampling: '', // 抽样数
        Colour: '', // 颜色
        Plug: '', // 插头
        CABLE_MOLD: '', // CABLE_MOLD
        Size: '', // 尺寸
        Packing: '', // 包装
        Other: '', // 其它
        Xrf: '', // XRF
        Icp: '', // ICQ
        Kkaoqta: '', // 可靠的实验其他
        Canshu1: '', // 参数设定1
        Canshu2: '', // 参数设定2
        Yqi1: '', // 测试仪器编号1
        Yqi2: '', // 测试仪器编号2
        Bniang: '', // 不良数
        Pding: '', // 判定
        Yqish1: '', // 量测仪器/编号1
        Yqish2: '', // 量测仪器/编号2
        Ycms: '', // 异常描述
        Beizhu: '', // 备注
        Hz: '', // 核准人
        Jyy: '', // 检验员
        Enteredby: '' // 录入人
      },
      isdisabled: true,
      dialogVisible: false,
      dialogVisiblee: false,
      dialogImageUrl: '',
      filedata: {
        Workorder: ''
      },
      fileList: [],
      value: localStorage.getItem('locale') || '',
      reset: false,
      isqpisboot: false,
      Texttable: false,
      Test_Item: '',
      Test_Index: '',
      Test_Numbers: '',
      Test_Supplier: '',
      Test_Product_name: '',
      qianhe: false,
      Signlog: [],
      reverse: true,
      prinform: {
        Boxlist: [
          {
            Box: 0,
            Boxquantity: 0
          }
        ],
        Printer: this.$store.state.PrinterData[0],
        Date: new Date()
      },
      dialogVisibleprin: false,
      yzdialogVisible: false,
      Mz_Numbers: '',
      Mz_Index: '',
      Mz_day: ''
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename'],
  created () {
    if (this.$store.state.UserData === null) {
      this.form.Enteredby = this.$store.state.Login.Username
    } else {
      this.form = this.$store.state.UserData
      this.FQCNumerb(this.form.Numbers)
      this.FQCSignloglist(this.form.Numbers)
    }
  },
  mounted () {

  },
  components: {
    fqcUpload,
    NewfqcTestsheet,
    NewFQCqianhe
  },
  methods: {
    mzTestbox (item, index) {
      this.Mz_day = item.Frequency
      this.Mz_Index = index
      this.yzdialogVisible = true
    },
    Keyenter (item, index) {
      this.form.Parcss[index].Text9 = ''
      this.indeexDomain(item, index)
    },
    ...mapMutations(['UserData']),
    ...mapMutations(['FQCNumerb', 'TabComponentFun', 'ConductIs']),
    Qianhecolse () {
      this.qianhe = false
    },
    fileopen (url) {
      this.ConductIs('')
      this.$store.state.Url = ''
      this.$nextTick(() => { // 异步关闭
        setTimeout(() => {
          // 在延迟2秒后执行的代码
          this.$store.state.Url = this.$Upload + '/' + url
          this.ConductIs('ConductIs')
        }, 500)
      })
      // window.open(url, '_blank')
    },
    indeexDomain (text, index) {
      this.form.Parcss[index].Text9 = ''
      const valany = []
      if (text.Text2 !== null && text.Text2 !== '' && text.Text2 !== undefined) {
        valany.push(parseFloat(text.Text2))
      }
      if (text.Text3 !== null && text.Text3 !== '' && text.Text3 !== undefined) {
        valany.push(parseFloat(text.Text3))
      }
      if (text.Text4 !== null && text.Text4 !== '' && text.Text4 !== undefined) {
        valany.push(parseFloat(text.Text4))
      }
      if (text.Text5 !== null && text.Text5 !== '' && text.Text5 !== undefined) {
        valany.push(parseFloat(text.Text5))
      }
      if (text.Text6 !== null && text.Text6 !== '' && text.Text6 !== undefined) {
        valany.push(parseFloat(text.Text6))
      }
      // this.form.Parcss[index].Text7 = Math.max.apply(null, valany)
      // this.form.Parcss[index].Text8 = Math.min.apply(null, valany)
      // 最大值
      // 最小值
      this.form.Parcss[index].Text7 = Math.max.apply(Math, valany)
      this.form.Parcss[index].Text8 = Math.min.apply(Math, valany)
      this.form.Parcss[index].Text9 = this.$PublicJs.Calculation(text.Text, this.form.Parcss[index].Text7, this.form.Parcss[index].Text8)
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    addDomain (item, index) {
      const contr = {
        Date: new Date(),
        Index: index + 1,
        Numbers: item.Numbers,
        Number: this.form.Number,
        Product_name: item.Product_name, // 品名规格
        Text: item.Text,
        Text1: item.Text1,
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        Text6: '',
        Text7: '',
        Text8: '',
        Text9: ''
      }
      this.form.Parcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    removeDomain (item) {
      var index = this.form.Parcss.indexOf(item)
      if (index !== -1) {
        this.form.Parcss.splice(index, 1)
      }
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    addlist (item, index, value) {
      if (value === this.$t('All.增加')) {
        this.addDomain(item, index)
        this.form.Parcss[index].Text9 = ''
        return
      }
      if (value === this.$t('All.删除')) {
        this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(() => {
            this.removeDomain(item)
            this.form.Parcss[index].Text9 = ''
          })
          .catch(() => {
            this.form.Parcss[index].Text9 = ''
          })
        return
      }
      if (value === this.$t('All.判定')) {
        this.form.Parcss[index].Text9 = ''
        this.indeexDomain(item, index)
      }
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + '-' + mm + '-' + dd
      return gettime
    },
    async Newfqcuserzancun (row, state) {
      if (state === 0) {
        for (let indexx = 0; indexx < row.Fqctestlist.length; indexx++) {
          if (row.Fqctestlist[indexx].Determinetype === 1) {
            if (row.Fqctestlist[indexx].Determine === '' || row.Fqctestlist[indexx].Determine === null || row.Fqctestlist[indexx].Determine === undefined) {
              return this.$message.error(`${row.Fqctestlist[indexx].Title}${this.$t('All.项实验室没有做出判断系统无法进行送呈只能暂存')}`)
            }
          }
        }
      }
      const fqcformpost = {
        UserData: this.$store.state.Login,
        FQC_User: row,
        Qianheclass: {
          Type: 0,
          State: state,
          Text: ''
        }
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/FQC/Newfqcuserzancun', fqcformpost)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.msg)
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.新FQC检验单') + '-' + row.Numbers)
    },
    async NewfqcJiean (row) {
      const fqcformpost = {
        UserData: this.$store.state.Login,
        FQC_User: this.form,
        Qianheclass: row
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/FQC/NewfqcJiean', fqcformpost)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      this.qianhe = false
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.新FQC检验单') + '-' + this.form.Numbers)
    },
    async NewfqcdataDel (row) {
      const fqcformpost = {
        UserData: this.$store.state.Login,
        FQC_User: row,
        Qianheclass: {
          Type: 0,
          State: 1,
          Text: this.$t('All.作废表单')
        }
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/FQC/NewfqcdataDel', fqcformpost)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.新FQC检验单') + '-' + row.Numbers)
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handSuccess (response, file, fileList) {
      this.fileList.push(response)
    },
    text9pass (index, state) {
      this.form.Parcss[index].Text9 = state
    },
    printContent () {
      const subOutputRankPrint = document.getElementById('print') // 要打印区域的元素id
      console.log(subOutputRankPrint.innerHTML)
      const newContent = subOutputRankPrint.innerHTML
      const oldContent = document.body.innerHTML
      document.body.innerHTML = newContent
      window.print()
      window.location.reload()
      document.body.innerHTML = oldContent
      return false
    },
    Testbox (item, index) {
      this.Test_Item = item
      this.Test_Index = index
      this.Test_Supplier = this.form.Customer
      this.Test_Product_name = this.form.Product_name
      this.Texttable = true
      this.Test_Numbers = ''
    },
    Texttablebool () {
      this.Texttable = false
    },
    Texttablefunadd (index, row) {
      this.form.Fqctestlist[index].Remarks = row
      this.form.Fqctestlist[index].Determine = ''
      this.Texttable = false
    },
    chuangjcompany () {
      var inputValue = this.form.Company
      // 移除非字母字符
      inputValue = inputValue.replace(/[^A-Za-z]/g, '')
      // 转换为大写
      inputValue = inputValue.toUpperCase()
      this.form.Company = inputValue
    },
    lookshiyan (row, index) {
      if (row.Remarks === '' || row.Remarks.length < 0) return this.$message.error('请先填写送测单')
      if (this.isdisabled) {
        this.Test_Numbers = row.Remarks
        this.Texttable = true
        return
      }
      this.$confirm(this.$t('All.查看实验单修改免做单'), this.$t('All.提示'), {
        cancelButtonText: this.$t('All.查看'),
        confirmButtonText: this.$t('All.重做实验'),
        type: 'warning'
      })
        .then(() => {
          this.Testbox(row, index)
        })
        .catch(() => {
          this.Test_Numbers = row.Remarks
          this.Texttable = true
        })
    },
    async FQCSignloglist (row) {
      try {
        const { data: res } = await this.$http.post('/api/FQC/FQCSignloglist?Numbers=' + row)
        if (res.status !== 200) return
        this.Signlog = res.response
      } catch (error) {
        alert(error)
      }
    },
    addDomainbut () {
      this.prinform.Boxlist.push({
        Box: '',
        Boxquantity: ''
      })
    },
    removeDomainbut (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          if (this.prinform.Boxlist.length === 1) {
            return this.$message.error(this.$t('All.最少保留一列'))
          }
          var index = this.prinform.Boxlist.indexOf(item)
          if (index !== -1) {
            this.prinform.Boxlist.splice(index, 1)
          }
        })
        .catch(() => {
        })
    },
    async Fqcprint (type) {
      var fqcprin = {
        Printer: this.prinform.Printer,
        Boxlist: this.prinform.Boxlist,
        Date: this.prinform.Date,
        From: this.form,
        UserData: this.$store.state.Login,
        Type: type
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/FQC/Fqcprint', fqcprin)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.msg)
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      this.qianhe = false
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.新FQC检验单') + '-' + this.form.Numbers)
    },
    async Gettestbaogao () {
      try {
        if (this.Mz_Numbers.trim().length === 0) {
          this.form.Fqctestlist[this.Mz_Index].Remarks = ''
          this.form.Fqctestlist[this.Mz_Index].Determine = ''
          this.yzdialogVisible = false
          return this.$message.error(this.$t('All.清空订单号成功'))
        }
        const { data: res } = await this.$http.get('/api/Newiqc/Gettestbaogao?Numbers=' + this.Mz_Numbers + '&Day=' + this.Mz_day)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.form.Fqctestlist[this.Mz_Index].Remarks = this.Mz_Numbers
        this.form.Fqctestlist[this.Mz_Index].Determine = res.response.Results
        this.Mz_Numbers = ''
        this.Mz_Index = ''
        this.Mz_day = ''
        this.yzdialogVisible = false
      } catch (error) {
        alert(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  min-width: 903px;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
</style>
